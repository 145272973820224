<template>
  <div :class="`${tab.key}Mode`">
    <div class="rejected" v-if="rejected">
      <div>違反行為により利用が制限されています。<br>違反行為をしていない場合でも、他ユーザーの利用制限に巻き込まれてしまう場合があります。<br>その場合、ご不便おかけしますが日を空けて再度お試しください。</div>
    </div>
    <div class="gameContainer" :class="{ reverseLayout }" v-else-if="data">
      <users class="gameContainer-users" :users="data.users" v-model:mute="mute" @updated="updateData" @entered="fetchLog" />
      <illusts class="gameContainer-illusts" :game="data" v-model:liveMode="liveMode" />
      <messages class="gameContainer-messages" :messages="data.messages" @updated="updateData" />
      <answer class="gameContainer-answer" @updated="updateData" :canSetTheme="data.illusts.length === 0" :liveMode="liveMode" />
    </div>
    <div class="switchLayout">
      <label class="switchLayout-button"><input type="checkbox" v-model="reverseLayout" :value="true"><i class="material-icons">switch_right</i> レイアウト変更</label>
    </div>
    <teleport to="#headerRight">
      <div class="options">
        <div class="options-item" @click="modalName = 'help'"><i class="material-icons">help</i><span>遊び方</span></div> |
        <div class="options-item" @click="modalName = 'updates'"><i class="material-icons">new_releases</i><span>更新情報</span></div> |
        <a class="options-item" href="https://laineus.com/contact/eshiritori" target="_blank"><i class="material-icons">mail_outline</i><span>お問合せ</span></a> |
        <router-link class="options-item" :to="{ name: 'Log', params: { page: 1 } }"><i class="material-icons">history</i><span>過去ログ</span></router-link>
      </div>
    </teleport>
    <log class="log" ref="log" :pickup="true" :award="false" />
    <log class="log award" ref="award" :pickup="true" :award="true" />
    <transition name="modal">
      <modal v-if="resultId" @close="resultId = null">
        <div class="resultImage">
          <img :src="`/log/game/${resultId}_answer.png`">
        </div>
      </modal>
    </transition>
    <transition name="modal">
      <modal v-if="modalName === 'inquiry'" @close="modalName = null" class="inquiry">
        <div class="modalTitle">【お問い合わせ】</div>
        <p>info@laineus.com 宛に、件名を「絵しりとりについて」などととしてお送りください。</p>
        <p>違反報告等に関するお問い合わせの場合、必ず利用していたニックネームを（記号等を含む場合省略せず正確に）記載してください。</p>
        <p>不具合報告や要望の際は、具体的にお書きください。</p>
        <ul>
          <li>× 「投稿ができない」</li>
          <li>○ 「自分のターンが来たのに、ボタンがグレーのままで、押しても反応しない」</li>
          <li>× 「使いづらい」</li>
          <li>○ 「〜のボタンが小さくて押しづらいので少し大きくしてほしい」</li>
        </ul>
      </modal>
      <modal v-else-if="modalName === 'help'" @close="modalName = null">
        <div class="modalTitle">【遊び方】</div>
        <ul>
          <li>前の人が何を描いたのか予想し、その最後の文字で始まる絵を投稿してください。</li>
          <li>最後の文字が「ぁぃぅぇぉゃゅょっー」の場合、その前の文字が次の頭の文字となります。<br><small>（例：前の人が「しちゅー」の場合、「ち」になるので、「ちゅーりっぷ」でも「ちず」でも正解）</small></li>
          <li>「ん」がついたり、投稿数が50になった場合、ゲーム終了となります。</li>
        </ul>
        <div class="modalTitle">【ユーザーと権限】</div>
        <ul>
          <li>荒らし防止のため、ゲストユーザーは回答とチャットのみ行えます</li>
          <li>全ての機能を解放するには、Googleアカウントによるユーザー登録を行い「登録済みユーザー」となるか、<br>利用開始から3日経って「承認済みゲストユーザー」となる必要があります</li>
          <li>違反を行ったユーザーは無期限に利用停止されます</li>
          <li>ゲストアカウントなど複数アカウントで違反を行った場合、全てのユーザーが利用停止されます</li>
        </ul>
      </modal>
      <modal v-else-if="modalName === 'updates'" @close="modalName = null">
        <div class="modalTitle">2022/03/01</div>
        <ul>
          <li>チャットウィンドウの高さを固定し、キャンバスの位置がズレないようにしました</li>
        </ul>
        <div class="modalTitle">2022/02/28</div>
        <ul>
          <li>システムが利用傾向を元に健全なユーザーを学習し、<br>違反者がBANされるまでの時間短縮と報復BANの防止を行いました</li>
        </ul>
        <div class="modalTitle">2022/02/23</div>
        <ul>
          <li>ズームイン/アウトツールを実装しました</li>
          <li>ズームイン/アウトのショートカット（Ctrl+スクロール）を実装しました</li>
          <li>ズームしたキャンバスの移動（Shift+ドラッグ）を実装しました</li>
          <li>過去ログに日付が表示されるようにしました</li>
        </ul>
        <div class="modalTitle">2022/02/21</div>
        <ul>
          <li>プロフィールアイコンを設定できるようにしました</li>
        </ul>
        <div class="modalTitle">2022/02/17</div>
        <ul>
          <li>スポイトで特定の色の取得ができない不具合を修正しました</li>
          <li>元に戻す/やり直し がテキストボックスの 元に戻す/やり直し と干渉してしまう問題を修正しました</li>
        </ul>
        <div class="modalTitle">2022/02/16</div>
        <ul>
          <li>前回描いていた途中の絵が復元されるようにしました</li>
          <li>レイヤー名を編集できるようにしました</li>
          <li>通知音設定/レイアウト設定が保存されるようにしました</li>
        </ul>
        <div class="modalTitle">2022/02/10</div>
        <ul>
          <li>各ツールへのキーボードショートカットを実装しました</li>
          <li>最大のレイヤー数を4枚から6枚に変更しました</li>
          <li>PC向けの画面レイアウトを2パターン切り替えられるようにしました</li>
        </ul>
        <div class="modalTitle">2021/11/11</div>
        <ul>
          <li>元に戻す/やり直し へのキーボードショートカットを実装しました</li>
        </ul>
        <div class="modalTitle">2021/06/11</div>
        <ul>
          <li>進行中のゲームのチャットの過去ログを見れるようにしました</li>
          <li>チャットウィンドウのスクロールの挙動を改善しました</li>
          <li>イラストウィンドウをマウスホイールでスクロールできるようにしました</li>
        </ul>
        <div class="modalTitle">2021/06/07</div>
        <ul>
          <li>ゲストユーザー/認証済みユーザー/登録済みユーザー の仕組みを実装しました</li>
        </ul>
        <div class="modalTitle">2020/12/20</div>
        <ul>
          <li>LIVEモードを実装しました</li>
          <li>殿堂入りコーナーを実装しました</li>
          <li>お気に入りボタンを実装しました</li>
        </ul>
        <div class="modalTitle">2020/11/26</div>
        <ul>
          <li>デザインをリニューアルしました</li>
          <li>違反報告機能を実装しました</li>
        </ul>
      </modal>
    </transition>
    <!-- Audio -->
    <audio :ref="audio.img" preload="auto"><source src="/audio/img.wav" type="audio/wav"></audio>
    <audio :ref="audio.chat" preload="auto"><source src="/audio/chat.wav" type="audio/wav"></audio>
    <audio :ref="audio.user" preload="auto"><source src="/audio/click.wav" type="audio/wav"></audio>
    <div v-show="!pollingAllowed" class="sleep" @click="allowPolling">
      <span class="material-icons">bedtime</span>
    </div>
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import { computed, onBeforeUnmount, provide, ref, reactive, toRefs, inject, watch } from 'vue'
import Users from '@/components/Users'
import Illusts from '@/components/Illusts'
import Messages from '@/components/Messages'
import Answer from '@/components/Answer'
import Log from '@/components/Log'
import Modal from '@/components/Modal'
const POLLING_TIME = 4 * 1000
const POLLING_TIME_ON_FAILED = 12 * 1000
const pollingAllowed = ref(true)
window.addEventListener('focus', () => {
  pollingAllowed.value = true
})
window.addEventListener('blur', () => {
  pollingAllowed.value = false
})
const allowPolling = () => {
  pollingAllowed.value = true
}
export default {
  props: ['tab'],
  components: { Users, Messages, Illusts, Answer, Log, Modal },
  beforeRouteLeave () {
    return !this.drawn || confirm('描いている途中のイラストがありますが、ページを移動してよろしいですか？')
  },
  setup () {
    const axios = inject('axios')
    const drawn = inject('drawn')
    const settings = inject('settings')
    const data = ref(null)
    const modalName = ref(null)
    const mute = ref(settings.getState().mute)
    const rejected = ref(false)
    const rejectedType = ref(null)
    const resultId = ref(null)
    const liveMode = ref(true)
    const reverseLayout = ref(settings.getState().lefty)
    const refs = reactive({
      log: null,
      award: null
    })
    provide('data', data)
    provide('reverseLayout', reverseLayout)
    const reachedMax = computed(() => data.value && data.value.illusts.length >= 50)
    provide('reachedMax', reachedMax)
    const gameOver = computed(() => data.value && data.value.illusts.some(v => v.end))
    provide('gameOver', gameOver)
    watch([() => mute.value, () => reverseLayout.value], () => {
      settings.setState({ mute: mute.value, lefty: reverseLayout.value })
    })
    const fetchLog = () => {
      if (refs.log) refs.log.fetch()
      if (refs.award) refs.award.fetch()
    }
    const fetchGame = () => {
      return axios.get('/api/game.php').then(res => {
        // res.data.illusts = Array(50).fill(res.data.illusts[0], 0, 50)
        // res.data.illusts[res.data.illusts.length - 1].end = true
        if (!reachedMax.value && res.data.illusts.length >= 50) {
          setTimeout(() => alert('投稿数が上限の50になりました。リセットして過去ログへ保存して下さい。'), 100)
        } else if (!gameOver.value && res.data.illusts.some(v => v.end)) {
          setTimeout(() => alert('「ん」がついてしまったため終了です！リセットして過去ログへ保存して下さい。'), 100)
        }
        if (data.value && data.value.game_id !== res.data.game_id) {
          resultId.value = data.value.game_id
          fetchLog()
          setTimeout(() => alert(`${res.data.name}さんがゲームをリセットしました`), 100)
        }
        data.value = res.data
      })
    }
    let stopped = false
    let timer = null
    const polling = () => {
      if (stopped) return
      if (!pollingAllowed.value) {
        timer = setTimeout(polling, POLLING_TIME)
        return
      }
      fetchGame().then(() => {
        timer = setTimeout(polling, POLLING_TIME)
      }).catch(e => {
        if (e.response?.status === 403) {
          rejected.value = true
        } else {
          timer = setTimeout(polling, POLLING_TIME_ON_FAILED)
        }
      })
    }
    polling()
    onBeforeUnmount(() => {
      stopped = true
      clearTimeout(timer)
    })
    const audio = {
      img: ref(null),
      chat: ref(null),
      user: ref(null)
    }
    const playSound = name => {
      if (mute.value) return
      audio[name].value.play()
    }
    provide('playSound', playSound)
    const updateData = newData => {
      const keys = ['users', 'illusts', 'messages', 'live', 'entered']
      keys.filter(key => (key in newData)).forEach(key => {
        data.value[key] = newData[key]
      })
    }
    // const inquiry = () => {
    //   const text = prompt('お問い合わせ内容を入力してください', '')
    //   if (!text) return
    //   const params = new URLSearchParams()
    //   params.append('text', text)
    //   axios.post('/api/inquiry.php', params).then(() => {
    //     alert('お問い合わせを送信しました')
    //   })
    // }
    return {
      ...toRefs(refs),
      drawn,
      rejected, rejectedType,
      resultId,
      liveMode,
      reverseLayout,
      modalName,
      mute,
      data,
      audio,
      pollingAllowed,
      allowPolling,
      fetchLog,
      updateData
    }
  }
}
</script>

<style lang="scss" scoped>
.gameContainer {
  position: relative;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 840px;
  grid-template-areas:
    'users users'
    'illusts illusts'
    'messages answer';
  margin: auto;
  width: 1200px;
  &-users {
    grid-area: users;
    margin-bottom: 15px;
  }
  &-answer {
    grid-area: answer;
  }
  &-illusts {
    grid-area: illusts;
    margin-bottom: 15px;
  }
  &-messages {
    grid-area: messages;
  }
  &:not(.reverseLayout) &-messages {
    margin-right: 15px;
  }
  &.reverseLayout &-messages {
    margin-left: 15px;
  }
  &.reverseLayout {
    grid-template-columns: 840px auto;
    grid-template-areas:
      'users users'
      'illusts illusts'
      'answer messages';
  }
}
.switchLayout {
  width: 1200px;
  margin: 10px auto 0;
  text-align: right;
  &-button {
    font-size: 12px;
    color: var(--orange);
    text-decoration: underline;
    cursor: pointer;
    input {
      display: none;
    }
    i {
      font-size: 16px;
      vertical-align: middle;
    }
  }
}
.log {
  margin-top: 50px;
}
.options {
  display: flex;
  color: var(--border-color);
  &-item {
    display: block;
    margin: 0 0.7em;
    color: var(--orange);
    text-decoration: underline;
    cursor: pointer;
    i {
      font-size: 14px;
      vertical-align: middle;
      margin-right: 2px;
    }
    span {
      vertical-align: middle;
    }
  }
}
.resultImage {
  width: 640px;
  text-align: center;
}
.ad {
  margin: 10px auto 0;
}
.sleep {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  span {
    font-size: 70px;
    color: rgba(255, 255, 255, 0.4);
  }
}
.rejected {
  max-width: 1200px;
  background: var(--tx-color);
  margin: 70px auto;
  padding: 20px;
  color: var(--bg-color);
  p {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
  ul {
    margin-top: 1em;
    padding-left: 2em;
    li {
      list-style-type: disc;
    }
  }
}
@media screen and (max-width: 1200px) {
  .gameContainer {
    display: block;
    grid-template-columns: auto;
    grid-template-areas:
      'users'
      'illusts'
      'messages'
      'answer';
    &.reverseLayout &-messages,
    &:not(.reverseLayout) &-messages {
      margin: 0 0 15px;
    }
  }
}
@media screen and (max-width: 900px) {
  .switchLayout {
    display: none;
  }
  .log {
    margin-top: 0;
  }
  .log.award {
    margin-top: 30px;
  }
  .gameMode {
    .gameContainer-answer,
    .log {
      display: none;
    }
  }
  .canvasMode {
    .gameContainer-users,
    .gameContainer-illusts,
    .gameContainer-messages,
    .log {
      display: none;
    };
  }
  .logMode {
    .gameContainer-users,
    .gameContainer-illusts,
    .gameContainer-messages,
    .gameContainer-answer {
      display: none;
    };
  }
}
</style>
