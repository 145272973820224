import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const app = initializeApp({
  apiKey: 'AIzaSyCIeZbYag7yp1V-TRo5iIQOanurJbRXCrc',
  authDomain: 'eshiritori-a97be.firebaseapp.com',
  projectId: 'eshiritori-a97be',
  storageBucket: 'eshiritori-a97be.appspot.com',
  messagingSenderId: '751709897949',
  appId: '1:751709897949:web:ff27ff70896d7eab524734',
  measurementId: 'G-CH6LCWNPNF'
})

export const analytics = getAnalytics(app)
export const authentication = getAuth(app)
export const firestore = getFirestore(app)

export default app
