<template>
  <div>
    <div v-if="data" class="logContainer">
      <h2>{{ awardMode ? '殿堂入り' : '過去' }}の絵しりとり <i class="helpButton material-icons" v-if="awardMode" @click="awardHelp = true">help</i></h2>
      <ul class="gameList">
        <li class="gameList-item" v-for="v in data.list" :key="v.game_id">
          <router-link class="gameItem" :to="{ name: 'LogDetail', params: { gameId: v.game_id }, query }">
            <img class="gameItem-img" :src="v.thumb" :alt="`絵しりとり No.${v.game_id}`">
            <div class="gameItem-title" v-if="v.theme">No.{{ v.game_id }}「{{ v.theme }}」</div>
            <div class="gameItem-title" v-else>No.{{ v.game_id }}の絵しりとり</div>
            <div class="gameItem-date">{{ dayjs(v.created).format('YYYY/MM/DD') }}</div>
            <vote-button class="gameItem-vote" :game="v" :entered="data.entered" />
            <award-icon class="gameItem-awardIcon" v-if="v.award" />
          </router-link>
        </li>
      </ul>
      <div class="footerLink" v-if="pickup">
        <router-link :to="{ name: 'Log', params: { page: 1 }, query }">{{ awardMode ? '殿堂入り' : 'ログ' }}一覧を見る</router-link>
      </div>
      <pager v-else :page="page" :max="data.max" :to="{ name: 'Log', query }" />
    </div>
    <div v-else class="logContainer">
      <loading />
    </div>
    <transition name="modal">
      <modal v-if="awardHelp" @close="awardHelp = false">
        <div class="modalTitle">【殿堂入り】</div>
        <p>現在の殿堂入りの目安は「ノーミス」「投稿20枚以上」「参加人数3人以上」です。</p>
      </modal>
    </transition>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import router from '@/router/index'
import { computed, ref, watch, inject } from 'vue'
import VoteButton from '@/components/VoteButton'
import AwardIcon from '@/components/AwardIcon'
import Pager from '@/components/Pager'
import Modal from '@/components/Modal'
import Loading from '@/components/Loading'
export default {
  components: { VoteButton, AwardIcon, Pager, Modal, Loading },
  props: {
    pickup: { type: Boolean, default: false },
    award: { type: Boolean, default: false }
  },
  setup (props) {
    const axios = inject('axios')
    const awardMode = computed(() => props.award || Boolean(router.currentRoute.value.query.award))
    const query = computed(() => awardMode.value ? { award: 'true' } : {})
    const page = computed(() => Number(router.currentRoute.value.params.page))
    const data = ref(null)
    const fetch = () => {
      data.value = null
      axios.get(`/api/log_list.php?page=${props.pickup ? '1' : page.value}&award=${awardMode.value}`).then(res => {
        if (props.pickup) res.data.list = res.data.list.slice(0, 5)
        data.value = res.data
      })
    }
    fetch()
    if (!props.pickup) {
      watch(() => page.value, value => {
        window.scrollTo(0, 0)
        if (value) fetch()
      })
    }
    return {
      dayjs,
      fetch,
      data,
      page,
      query,
      awardMode,
      awardHelp: ref(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.logContainer {
  margin: auto;
  max-width: 1200px;
  padding: 10px;
  background: var(--bg-color);
  border-radius: var(--radius);
}
.helpButton {
  font-size: 18px;
  cursor: pointer;
}
.gameList {
  &-item {
    margin: 8px;
    display: inline-block;
    vertical-align: top;
  }
}
.gameItem {
  position: relative;
  display: block;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: var(--radius);
  text-shadow: 1px 1px 0 var(--bg-color), -1px 1px 0 var(--bg-color), 1px -1px 0 var(--bg-color), -1px -1px 0 var(--bg-color), 0px 1px 0 var(--bg-color), 0px -1px 0 var(--bg-color), 1px 0px 0 var(--bg-color), -1px 0px 0 var(--bg-color);
  text-decoration: none;
  color: var(--tx-color);
  &-title {
    max-width: 200px;
    font-weight: bold;
  }
  &-date {
    font-size: 11px;
  }
  &-vote {
    position: absolute;
    right: 12px;
    bottom: 16px;
  }
  &-awardIcon {
    position: absolute;
    top: -12px;
    left: -12px;
  }
}
.footerLink {
  margin-top: 5px;
  text-align: center;
}
@media screen and (max-width: 773px) {
  .gameList-item {
    margin: 1%;
    width: 31.33%;
  }
  .gameItem-title {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .gameList-item {
    margin: 1%;
    width: 48%;
  }
}
</style>
