<template>
  <modal class="illustModal" @close="$emit('close')">
    <div class="modalTitle">アイコン設定</div>
    <template v-if="data && !isLoading">
      <p class="notFound" v-if="!data.list.length">イラストがまだありません</p>
      <ul class="illustList">
        <li class="illustList-item" v-for="v in data.list" :key="v.illust_id" @click="setIcon(v.illust_id)">
          <img :src="v.path" :alt="`絵しりとり No.${v.game_id}`">
        </li>
      </ul>
      <pager v-model:page="page" :max="data.max" />
    </template>
    <loading v-else />
  </modal>
</template>

<script>
import dayjs from 'dayjs'
import { ref, watch, inject } from 'vue'
import Pager from '@/components/Pager'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
export default {
  components: { Pager, Loading, Modal },
  emits: ['close', 'updated'],
  props: {
    userId: null
  },
  setup (props, { emit }) {
    const axios = inject('axios')
    const page = ref(1)
    const data = ref(null)
    const isLoading = ref(false)
    const fetch = () => {
      data.value = null
      axios.get(`/api/user.php?page=${page.value}&user_id=${props.userId}`).then(res => {
        data.value = res.data
      })
    }
    fetch()
    watch(() => page.value, fetch)
    const setIcon = illustId => {
      if (isLoading.value) return
      isLoading.value = true
      const params = new URLSearchParams()
      params.append('illust_id', illustId)
      return axios.post('/api/profile.php', params).then(res => {
        emit('updated', res.data)
        emit('close')
      }).catch(() => {
        alert('プロフィールアイコンの設定に失敗しました')
      }).finally(() => {
        isLoading.value = false
      })
    }
    return {
      dayjs,
      fetch,
      data,
      isLoading,
      page,
      setIcon
    }
  }
}
</script>

<style lang="scss" scoped>
.illustModal ::v-deep(.modalContainer) {
  max-width: 800px;
}
.illustList {
  .illustList-item {
    width: 23%;
    margin: 1%;
    display: inline-block;
    vertical-align: top;
    background: white;
    cursor: pointer;
    img {
      margin: 0;
      display: block;
    }
  }
}
.notFound {
  padding: 30px 10px;
  text-align: center;
}
</style>
