<template>
  <div class="illustComponent">
    <div class="liveMode" :class="{ active: liveMode }" v-show="player?.me" @click="$emit('update:liveMode', !liveMode)">
      <span class="liveMode-check" v-show="liveMode"><i class="material-icons">check_box</i></span>
      <span class="liveMode-check" v-show="!liveMode"><i class="material-icons">check_box_outline_blank</i></span>
      LIVEモード
      <i class="liveMode-help material-icons" @click.stop="help = 'live'">help</i>
    </div>
    <div class="illustComponent-theme">
      <template v-if="game.theme">お題: 「{{ game.theme }}」</template>
      <template v-else-if="game.illusts.length">お題なし</template>
      <template v-else>お題未定<small>（一人目の投稿者が決めます）</small></template>
      <i class="material-icons" @click="help = 'theme'">help</i>
    </div>
    <div class="illustComponent-inner" ref="list" @wheel="onWheel">
      <ul class="illustList">
        <li v-for="v in game.illusts" :key="v.illust_id" class="illustList-item" @click="selected = v">
          <illust class="illustList-item-illust" :illust="v" />
          <img class="illustList-item-arrow" v-if="!v.end" src="/img/arrow.png" alt="">
        </li>
        <li class="illustList-item drawing" v-if="game.illusts[game.illusts.length - 1]?.end">
          <div class="drawing-name" v-if="game.illusts.length >= 50">投稿数が50に達しました<br><small>リセットしてください</small></div>
          <div class="drawing-name" v-else>「ん」がつきました<br><small>リセットしてください</small></div>
        </li>
        <li class="illustList-item drawing" :class="{ live: game.live }" v-else-if="player">
          <div class="drawing-brush" />
          <img class="drawing-liveImg" :src="game.live" v-if="game.live">
          <div class="drawing-name">{{ player.name }}さん<br><small>の番です</small></div>
          <div class="drawing-icon" v-show="game.live">LIVE</div>
        </li>
        <li class="illustList-item drawing" :class="{ live: game.live }" v-else>
          <div class="drawing-brush" />
          <div class="drawing-name">参加者待ち</div>
        </li>
        <li class="illustList-item" v-if="canShowAd" style="width:240px;height:162px;"><ad style="width:240px;height:162px;" /></li>
      </ul>
    </div>
    <transition name="modal">
      <modal v-if="selected" @close="selected = null">
        <illust class="illustList-item-illust" :illust="selected" />
        <ad class="ad" style="height:162px;margin:10px auto;" />
      </modal>
    </transition>
    <transition name="modal">
      <modal v-if="help === 'theme'" @close="help = null">
        <div class="modalTitle">【絵しりとりのお題】</div>
        <p>お題は、何かのテーマに沿って絵しりとりをしたい場合に設定できます。</p>
        <p>お題は最初のイラストの投稿者が設定できます。<br>後から変更することはできません。</p>
        <p>お題は未設定で始めることもできます。</p>
      </modal>
      <modal v-else-if="help === 'live'" @close="help = null">
        <div class="modalTitle">【LIVEモード】</div>
        <p>LIVEモードを有効にすると、描いている様子を他のユーザーに共有することができます。</p>
      </modal>
    </transition>
  </div>
</template>

<script>
import { computed, inject, onMounted, onUpdated, ref, watch } from 'vue'
import Illust from '@/components/Illust'
import Modal from '@/components/Modal'
import Ad from '@/components/Ad'
export default {
  components: { Illust, Modal, Ad },
  props: ['game', 'liveMode'],
  setup (props) {
    const isFirstTime = inject('isFirstTime')
    const canShowAd = ref(!isFirstTime)
    if (isFirstTime) {
      setTimeout(() => {
        canShowAd.value = true
        scrollUpdateFlg = true
      }, 60 * 1000)
    }
    const list = ref(null)
    const selected = ref(null)
    const help = ref(null)
    const playSound = inject('playSound')
    const player = computed(() => props.game.users.find(v => v.current))
    let scrollUpdateFlg = false
    const updateScroll = () => {
      list.value.scrollLeft = list.value.scrollWidth - list.value.clientWidth
    }
    watch(() => props.game.illusts.length, () => {
      scrollUpdateFlg = true
      playSound('img')
    })
    onMounted(updateScroll)
    onUpdated(() => {
      if (!scrollUpdateFlg) return
      scrollUpdateFlg = false
      updateScroll()
    })
    const onWheel = e => {
      if (list.value.scrollWidth === list.value.clientWidth) return
      if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) return
      list.value.scrollLeft += e.deltaY
      e.preventDefault()
    }
    return {
      list,
      selected,
      help,
      player,
      canShowAd,
      onWheel
    }
  }
}
</script>

<style lang="scss" scoped>
.illustComponent {
  position: relative;
  padding: 5px;
  background: var(--bg-color);
  border-radius: var(--radius);
  overflow: hidden;
  &-theme {
    padding: 5px 8px;
    font-size: 17px;
    font-weight: bold;
    small {
      font-weight: normal;
    }
    i {
      margin-left: 10px;
      font-size: 15px;
      cursor: pointer;
    }
  }
  &-inner {
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 5px;
  }
}
.illustList {
  display: flex;
  width: max-content;
  max-width: none;
  &-item {
    position: relative;
    width: 240px;
    padding: 0 10px;
    &:not(:first-child) {
      border-left: 1px dashed var(--border-color);
    }
    &-arrow {
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      transform: translate(50%, -50%);
      z-index: 1;
    }
    &:last-child &-arrow {
      opacity: 0.5;
      animation: blinking infinite 0.6s alternate;
    }
  }
  &-empty {
    padding: 20px 4px;
    font-size: 13px;
  }
}
.liveMode {
  float: right;
  margin: 5px 9px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &.active {
    color: #C00;
  }
  &-check i {
    margin-right: 4px;
    display: block;
    font-size: 17px;
  }
  &-help {
    margin-left: 7px;
    font-size: 16px;
    color: var(--tx-color);
  }
}
.drawing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 162px;
  position: relative;
  padding: 0 10px;
  &-brush {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/img/brush.svg') center center;
    background-size: 40%;
    background-repeat: no-repeat;
    opacity: 0.2;
  }
  &-liveImg {
    position: absolute;
    top: 0;
    left: 20px;
  }
  &-name {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    // color: var(--green);
    line-height: 1.2;
    text-align: center;
    text-shadow: 1px 1px 0 var(--bg-color), -1px 1px 0 var(--bg-color), 1px -1px 0 var(--bg-color), -1px -1px 0 var(--bg-color), 0px 1px 0 var(--bg-color), 0px -1px 0 var(--bg-color), 1px 0px 0 var(--bg-color), -1px 0px 0 var(--bg-color);
    animation: blinking infinite 0.6s alternate;
    cursor: default;
  }
  &.live &-name {
    top: 60px;
    font-size: 15px;
  }
  &-icon {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 0 7px;
    background: #C00;
    font-weight: bold;
    font-size: 11px;
    color: #FFF;
    border-radius: 20px;
    cursor: default;
  }
}
@keyframes blinking {
  from { opacity: 0.6; }
  to { opacity: 1; }
}
</style>
