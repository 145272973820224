<template>
  <div>
    <painter ref="painterRef" @updated="onUpdated" />
    <form @submit.prevent="postAnswer" class="answerForm">
      <ul class="answerForm-inputList">
        <li class="answerForm-inputList-item" v-if="canSetTheme">
          <input type="text" placeholder="絵しりとりのお題（未設定でもOK）" v-model="theme" maxlength="20">
          <i class="material-icons" @click="help = 'theme'">help</i>
        </li>
        <li class="answerForm-inputList-item">
          <input type="text" placeholder="あなたのイラストの名前（ひらがな）" v-model="model" maxlength="15">
          <i class="material-icons" @click="help = 'answer'">help</i>
        </li>
      </ul>
      <loading v-if="isLoading" class="loading" />
      <button v-else class="answerForm-button" :class="{ disabled: !canAnswer }"><i class="material-icons">create</i>{{ canSetTheme ? '最初' : '次' }}のイラストを投稿</button>
    </form>
    <transition name="modal">
      <modal v-if="help" @close="help = null">
        <div v-if="help === 'theme'">
          <div class="modalTitle">【絵しりとりのお題】</div>
          <p>お題は、何かのテーマに沿って絵しりとりをしたい場合に設定できます。</p>
          <p>お題は最初のイラストの投稿者が設定できます。<br>後から変更することはできません。<br>お題を特に決めたくない場合は、空欄で投稿してください。</p>
          <p>皆が参加しやすいお題がおすすめです。<br>例: 「どうぶつ」「冬といえば」など</p>
        </div>
        <div v-if="help === 'answer'">
          <div class="modalTitle">【あなたのイラストの名前】</div>
          <p>前の人のイラストの名前ではなく、あなたが書いたイラストの名前を入力してださい。</p>
          <p>1文字目が前の人のイラストの最後の文字になれば正解です。</p>
          <p>ひらがなで入力してください。</p>
        </div>
      </modal>
    </transition>
  </div>
</template>

<script>
import { computed, inject, nextTick, reactive, ref, watch } from 'vue'
import Painter from '@/components/Painter'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
export default {
  components: { Painter, Loading, Modal },
  emits: ['updated'],
  props: ['canSetTheme', 'liveMode'],
  setup (props, context) {
    const axios = inject('axios')
    const model = ref('')
    const theme = ref('')
    const help = ref('')
    const painterRef = ref(null)
    const isLoading = ref(false)
    const data = inject('data')
    const reachedMax = inject('reachedMax')
    const gameOver = inject('gameOver')
    const drawn = inject('drawn')
    const ownTurn = computed(() => {
      if (reachedMax.value || gameOver.value) return false
      return data.value.users.some(u => u.current && u.me)
    })
    const canAnswer = computed(() => {
      return ownTurn.value && drawn.value && model.value
    })
    const postAnswer = () => {
      if (!canAnswer.value) return
      if (!model.value.match(/^[ぁ-んー]+$/)) {
        alert('回答はひらがなで入力してください')
        return
      }
      if (!data.value.entered.semi_verified && !data.value.entered.verified && model.value.endsWith('ん')) {
        alert('荒らし防止の為、ゲストユーザーは利用開始から3日経過するまで「ん」のつく回答ができません')
        return
      }
      isLoading.value = true
      painterRef.value.getImageData().then(imageData => {
        const params = new FormData()
        params.append('title', model.value)
        if (props.canSetTheme) params.append('theme', theme.value)
        params.append('image', imageData)
        params.append('revision', painterRef.value.layerData.revision)
        model.value = null
        return axios.post('/api/answer.php', params).then(res => {
          context.emit('updated', res.data)
          painterRef.value.resetCanvas()
          localStorage.removeItem('backup')
        }).catch(() => {
          alert('投稿に失敗しました')
        }).finally(() => {
          isLoading.value = false
        })
      })
    }
    const liveCtr = reactive({
      blocked: false,
      pending: false
    })
    const postLiveImage = () => {
      if (!ownTurn.value || !props.liveMode) return
      painterRef.value.getThumbData().then(imageData => {
        const params = new FormData()
        params.append('image', imageData)
        return axios.post('/api/live.php', params).then(res => {
          context.emit('updated', res.data)
        })
      })
    }
    const deleteLiveImage = () => {
      if (!ownTurn.value) return
      axios.post('/api/live.php').then(res => {
        context.emit('updated', res.data)
      })
    }
    const updateLive = () => {
      if (liveCtr.blocked) {
        liveCtr.pending = true
        return
      }
      postLiveImage()
      liveCtr.blocked = true
      setTimeout(() => {
        liveCtr.blocked = false
        if (liveCtr.pending) {
          liveCtr.pending = false
          updateLive()
        }
      }, 1500)
    }
    watch(() => props.liveMode, () => {
      props.liveMode ? updateLive() : deleteLiveImage()
    })
    const onUpdated = () => {
      nextTick(() => {
        updateLive()
      })
    }
    return {
      painterRef,
      model,
      theme,
      help,
      postAnswer,
      canAnswer,
      isLoading,
      onUpdated
    }
  }
}
</script>

<style lang="scss" scoped>
.answerForm {
  margin-top: 10px;
  &-inputList {
    display: flex;
    justify-content: space-between;
    &-item {
      position: relative;
      flex: 1;
      margin-bottom: 10px;
      &:not(:first-child) {
        margin-left: 10px;
      }
      i {
        position: absolute;
        top: 8px;
        right: 7px;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
.loading {
  margin: 17px auto 4px;
}
@media screen and (max-width: 900px) {
  .answerForm-inputList {
    flex-direction: column;
    &-item:not(:first-child) {
      margin-left: 0;
    }
  }
}
</style>
