<template>
  <div class="usersComponent">
    <ul class="userList">
      <li class="userList-item" :class="{ active: v.current }" v-for="v in users" :key="v.user_id"><user-name :user="v" :reportable="!v.me" /><small v-if="v.me">（あなた）</small></li>
      <li class="userList-empty" v-if="!users.length">誰もいません</li>
    </ul>
    <loading v-if="isLoading" />
    <div v-else-if="me" class="myInfo">
      <button class="myInfo-button" :class="{ disabled: !canRotate }" @click="rotateTurn"><i class="material-icons">autorenew</i>ターンを移動</button>
      <button class="myInfo-button" :class="{ disabled: !canReset }" @click="reset"><i class="material-icons">not_interested</i>ゲームリセット</button>
      <button class="myInfo-button cancel" @click="logout"><i class="material-icons">logout</i>退室</button>
      <button class="myInfo-button cancel" @click="modalName = 'setting'"><i class="material-icons">settings</i>設定</button>
    </div>
    <div v-else>
      <div class="entryForm" v-if="data.entered">
        <div class="entryForm-name" @click="myProfile = true">
          {{ data.entered.name }}
          <i class="material-icons" v-if="data.entered.verified">verified</i>
          <i class="material-icons" v-else-if="data.entered.semi_verified">check_circle_outline</i>
        </div>
        <button class="entryForm-button" @click="reentry"><i class="material-icons">login</i>ゲームに参加</button>
        <button class="entryForm-button cancel" @click="modalName = 'setting'"><i class="material-icons">settings</i>設定</button>
      </div>
      <div class="entryForm" v-else>
        <button class="entryForm-button" @click.prevent="modalName = 'entry'"><i class="material-icons">sentiment_satisfied_alt</i>ゲスト</button>
        <button class="entryForm-button" @click.prevent="modalName = 'signin'"><i class="material-icons">verified</i>ログイン</button>
        <button class="entryForm-button cancel" @click="modalName = 'setting'"><i class="material-icons">settings</i>設定</button>
      </div>
    </div>
    <transition name="modal">
      <modal class="myProfile" v-if="modalName === 'entry'" @close="modalName = null">
        <div class="modalTitle">ゲストでゲームに参加</div>
        <form @submit.prevent="entry">
          <input type="text" v-model="nickname" placeholder="名前" maxlength="10">
          <button :class="{ disabled: !nickname.trim() }">ゲームに参加</button>
          <button class="cancel" @click.prevent="modalName = null">キャンセル</button>
        </form>
      </modal>
      <modal class="myProfile" v-else-if="modalName === 'signin'" @close="modalName = null">
        <div class="modalTitle">ログイン</div>
        <div :ref="authCotainer"></div>
      </modal>
      <modal class="myProfile" v-else-if="modalName === 'setting'" @close="modalName = null">
        <template v-if="data.entered">
          <user-info :user="data.entered" />
          <router-link class="button" :to="{ name: 'User', params: { userId: data.entered.user_id } }">イラスト一覧</router-link>
          <button @click="modalName = 'icon'">アイコンを変更</button>
          <button v-if="data.entered.verified || data.entered.semi_verified" @click="modalName = 'rename'">名前を変更</button>
          <button v-if="!data.entered.verified" @click="modalName = 'signin'"><i class="material-icons">verified</i>ユーザーを登録</button>
        </template>
        <button @click="$emit('update:mute', !mute)"><i class="material-icons">{{ mute ? 'volume_off' : 'volume_up' }}</i>通知音<template v-if="mute">（ミュート中）</template></button>
        <button class="cancel" @click="modalName = null">閉じる</button>
      </modal>
      <icon-select-modal v-else-if="modalName === 'icon'" :userId="data.entered.user_id" @updated="$emit('updated', $event)" @close="modalName = null" />
      <modal class="myProfile" v-else-if="modalName === 'rename'" @close="modalName = null">
        <div class="modalTitle">名前を変更</div>
        <p>名前を変更できる回数には上限があります。<br>他のユーザーの混乱を避けるため、必要なときのみ変更してください。</p>
        <input type="text" v-model="newName" placeholder="新しい名前" maxlength="10">
        <button @click="rename" :class="{ disabled: !newName.trim() }">名前を変更</button>
        <button class="cancel" @click="modalName = null">閉じる</button>
      </modal>
      <modal class="agreement" v-else-if="agreementModal" @close="closeAgreement">
        <div class="modalTitle">利用に関する同意事項</div>
        <p>当サイト利用者は、利用方法・投稿内容に対し、<strong>一切の責任が利用者に帰すことを承諾</strong>します。</p>
        <p>■ 当サイトでは以下の行為を違反行為と定めます</p>
        <ul>
          <li>ゲームの進行を阻害する行為</li>
          <li>不適切なイラスト・チャットの投稿</li>
          <li>誹謗中傷</li>
          <li>違反報告の悪用</li>
          <li>その他管理者が不適切と判断した行為</li>
        </ul>
        <p>■ 違反行為に対する法的措置は以下の流れで実施します</p>
        <ol>
          <li>管理者（又は利用者）は、プロバイダに対し「<strong>発信者情報開示請求</strong>」を経て違反者の情報を取得</li>
          <li>管理者（又は利用者）は、<strong>違反者の住所宛へ損害賠償請求に関する内容証明を送付</strong></li>
          <li>違反者は、請求に応じるか、拒否して裁判を行う</li>
          <li>管理者による請求の場合、<strong>賠償金をサイト運営費に補填</strong>される</li>
        </ol>
        <label><input type="checkbox" v-model="agree"> 上記に同意する</label>
        <button :class="{ disabled: !agree }" @click.prevent.stop="entry">入室</button>
        <button class="cancel" @click.prevent.stop="closeAgreement">キャンセル</button>
      </modal>
    </transition>
  </div>
</template>

<script>
import { authentication } from '@/firebase/app'
import { GoogleAuthProvider } from 'firebase/auth'
import { auth } from 'firebaseui'
import { computed, inject, ref, watch } from 'vue'
import UserName from '@/components/UserName'
import Modal from '@/components/Modal'
import IconSelectModal from '@/components/IconSelectModal'
import UserInfo from '@/components/UserInfo'
import Loading from './Loading.vue'
export default {
  props: ['users', 'mute'],
  emits: ['updated', 'entered', 'update:mute'],
  components: { UserName, Modal, IconSelectModal, UserInfo, Loading },
  setup (props, context) {
    const axios = inject('axios')
    const loading = ref(false)
    const nickname = ref('')
    const modalName = ref(null)
    const newName = ref('')
    const playSound = inject('playSound')
    const data = inject('data')
    const me = computed(() => props.users.find(u => u.me))
    const reachedMax = inject('reachedMax')
    const gameOver = inject('gameOver')
    const gameEnd = computed(() => reachedMax.value || gameOver.value)
    const canControl = computed(() => gameEnd.value || data.value.entered?.verified || data.value.entered?.semi_verified)
    const canReset = computed(() => {
      if (gameEnd.value) return true
      return data.value.illusts.length > 0
    })
    const canRotate = computed(() => {
      if (gameEnd.value) return false
      return data.value.users.length > 1
    })
    watch(() => props.users.length, () => {
      playSound('user')
    })
    watch(() => me.value?.current, newValue => {
      if (!newValue) return
      playSound('user')
      setTimeout(() => alert('あなたのターンです'), 100)
    })
    const agreementModal = ref(false)
    const agree = ref(false)
    const closeAgreement = () => {
      agree.value = false
      agreementModal.value = false
    }
    let ui
    const authCotainer = (element) => {
      if (!element) return
      if (!ui) {
        ui = new auth.AuthUI(authentication)
      }
      ui.start(element, {
        signInFlow: 'popup',
        signInOptions: [{ provider: GoogleAuthProvider.PROVIDER_ID, requireDisplayName: true }],
        callbacks: {
          signInSuccessWithAuthResult: (authResult) => {
            const idToken = authResult.user.accessToken
            console.log(idToken)
            const params = new URLSearchParams()
            params.append('token', idToken)
            loading.value = true
            modalName.value = null
            axios.post('/api/signin.php', params).then(res => {
              context.emit('updated', res.data)
              context.emit('entered')
            }).catch(() => {
              alert('入室に失敗しました')
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }
    const entry = () => {
      if (loading.value) return
      const name = nickname.value.trim()
      if (!name) return
      if (props.users.some(u => u.name === name)) {
        alert('同じ名前のユーザーがいます')
        return
      }
      // if (!agree.value) {
      //   agreementModal.value = true
      //   return
      // }
      // agreementModal.value = false
      const params = new URLSearchParams()
      params.append('name', name)
      loading.value = true
      return axios.post('/api/entry.php', params).then(res => {
        nickname.value = ''
        modalName.value = null
        context.emit('updated', res.data)
        context.emit('entered')
      }).catch(() => {
        alert('失敗しました')
      }).finally(() => {
        loading.value = false
      })
    }
    const reentry = () => {
      if (loading.value) return
      loading.value = true
      return axios.post('/api/reentry.php').then(res => {
        context.emit('updated', res.data)
      }).catch(() => {
        alert('参加に失敗しました')
      }).finally(() => {
        loading.value = false
      })
    }
    const rotateTurn = () => {
      if (loading.value) return
      if (!canRotate.value) return
      if (!canControl.value && !me.value.current) return alert('ゲストユーザーは利用開始3日後から他の人のターンを移動できます')
      if (!confirm('次の人にターンを移動しますか？')) return
      loading.value = true
      return axios.post('/api/rotate.php').then(res => {
        context.emit('updated', res.data)
      }).catch(() => {
        alert('ターンの移動に失敗しました')
      }).finally(() => {
        loading.value = false
      })
    }
    const logout = () => {
      if (loading.value) return
      loading.value = true
      return axios.post('/api/logout.php').then(res => {
        context.emit('updated', res.data)
      }).catch(() => {
        alert('退室に失敗しました')
      }).finally(() => {
        loading.value = false
      })
    }
    const reset = () => {
      if (loading.value) return
      if (!canReset.value) return
      if (!canControl.value) return alert('ゲストユーザーは利用開始3日後からリセットできます')
      if (!confirm('リセットすると現在のやりとりが消えて、過去ログに保存されます。本当にリセットしますか？')) return
      loading.value = true
      return axios.post('/api/reset.php').then(res => {
        context.emit('updated', { messages: [], illusts: [] })
      }).catch(() => {
        alert('リセットに失敗しました')
      }).finally(() => {
        loading.value = false
      })
    }
    const rename = () => {
      if (loading.value) return
      const name = newName.value.trim()
      if (!name) return
      if (props.users.some(u => u.name === name)) {
        alert('同じ名前のユーザーがいます')
        return
      }
      loading.value = true
      const params = new URLSearchParams()
      params.append('name', name)
      return axios.post('/api/profile.php', params).then(res => {
        context.emit('updated', res.data)
      }).catch(() => {
        alert('名前の変更に失敗しました')
      }).finally(() => {
        loading.value = false
        newName.value = ''
        modalName.value = null
      })
    }
    return {
      data,
      isLoading: loading,
      me,
      modalName,
      nickname,
      newName, rename,
      authCotainer,
      entry, reentry, logout,
      rotateTurn, reset,
      canRotate, canReset,
      agree, agreementModal, closeAgreement
    }
  }
}
</script>

<style lang="scss" scoped>
.usersComponent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.userList {
  margin-right: 15px;
  min-height: 35px;
  flex: 1;
  padding: 4px;
  background: var(--bg-color);
  border-radius: var(--radius);
  font-size: 0;
  &-item {
    height: 24px;
    margin: 2px;
    display: inline-flex;
    align-items: center;
    padding: 2px 6px;
    vertical-align: middle;
    background: var(--tx-color-light);
    border-radius: 6px;
    font-size: 11px;
    color: #FFF;
    &.active {
      background: var(--green);
      font-weight: bold;
    }
    small {
      font-size: 10px;
      font-weight: normal;
    }
  }
  &-empty {
    margin: 4px 10px;
    font-size: 13px;
  }
}
.entryForm {
  display: flex;
  justify-content: center;
  align-items: center;
  &-name {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    .material-icons {
      font-size: 16px;
    }
  }
  &-button {
    white-space: nowrap;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
.myInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  &-button {
    min-width: 80px;
    width: max-content;
    white-space: nowrap;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
.myProfile {
  .modalTitle i {
    margin-left: 2px;
    font-size: 13px;
  }
  .button,
  button {
    margin-top: 10px;
  }
}
.agreement {
  label {
    margin: 15px auto;
    padding: 10px;
    display: block;
    text-align: center;
  }
  strong {
    color: #C00;
    font-weight: normal;
  }
  button {
    margin-top: 15px;
  }
}
@media screen and (max-width: 900px) {
  .userList {
    margin: 0;
  }
  .entryForm {
    margin: 10px auto auto;
  }
  .usersComponent {
    display: block;
  }
  .myInfo {
    margin-top: 15px;
  }
}
@media screen and (max-width: 460px) {
  .myInfo {
    display: grid;
    grid-template-columns: 150px 150px;
    text-align: center;
    &-button {
      width: 90%;
      margin: 5px auto;
    }
  }
}
</style>

<style lang="scss">
.firebaseui-card-content {
  .firebaseui-idp-list {
    margin: 15px auto;
  }
  .firebaseui-list-item {
    list-style: none;
  }
  .firebaseui-idp-button {
    font-size: 14px;
    color: var(--tx-color);
  }
  .firebaseui-idp-icon {
    width: 20px;
    margin: 5px 10px 0 0;
  }
  .firebaseui-idp-text-short {
    display: none;
  }
}
</style>
