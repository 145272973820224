<template>
  <div class="illust">
    <div class="illust-name"><user-name :user="illust" :reportable="true" /></div>
    <img class="illust-img" :src="illust.path" :alt="illust.name">
    <div class="illust-title">{{ illust.title }}</div>
    <img class="illust-answer" :src="illust.correct ? '/img/maru.png' : '/img/batsu.png'" alt="" v-if="!illust.start">
  </div>
</template>

<script>
import UserName from '@/components/UserName'
export default {
  props: ['illust'],
  components: { UserName }
}
</script>

<style lang="scss" scoped>
.illust {
  position: relative;
  padding: 0 10px;
  cursor: pointer;
  &:not(:first-child) {
    border-left: 1px dashed var(--border-color);
  }
  &-name {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #791;
    text-shadow: 1px 1px 0 var(--bg-color), -1px 1px 0 var(--bg-color), 1px -1px 0 var(--bg-color), -1px -1px 0 var(--bg-color), 0px 1px 0 var(--bg-color), 0px -1px 0 var(--bg-color), 1px 0px 0 var(--bg-color), -1px 0px 0 var(--bg-color);
  }
  &-title {
    position: relative;
    margin: -20px auto 0;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-shadow: 1px 1px 0 var(--bg-color), -1px 1px 0 var(--bg-color), 1px -1px 0 var(--bg-color), -1px -1px 0 var(--bg-color), 0px 1px 0 var(--bg-color), 0px -1px 0 var(--bg-color), 1px 0px 0 var(--bg-color), -1px 0px 0 var(--bg-color);
  }
  &-answer {
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 80px;
    transform: translateX(-75%);
    z-index: 1;
  }
}
</style>
