<template>
  <div class="messageComponent">
    <div class="messageComponent-list">
      <ul class="messageList" ref="list">
        <li class="messageList-item readMore" v-if="messages.length >= 50">
          <router-link :to="{ name: 'LogDetail', params: { gameId: data.game_id } }">すべて見る</router-link>
        </li>
        <li v-for="v in messages" :key="v.chat_id" class="messageList-item">
          <template v-if="v.type === 'message'">
            <span class="messageList-item-name" :class="{ loggedInUser: !!v.name }"><user-name :user="v" :reportable="true" />:</span>
            <span class="messageList-item-text">{{ v.text }}</span>
          </template>
          <template v-else-if="v.type === 'rotate'">
            <span class="messageList-item-system"><strong><user-name :user="v" :reportable="true" /></strong>さんがターンを移動しました</span>
          </template>
          <template v-else-if="v.type === 'start'">
            <span class="messageList-item-system"><strong><user-name :user="v" :reportable="true" /></strong>さんがゲームを開始しました</span>
          </template>
          <template v-else-if="v.type === 'enter'">
            <span class="messageList-item-system"><strong><user-name :user="v" :reportable="true" /></strong>さんが入室しました</span>
          </template>
          <template v-else-if="v.type === 'leave'">
            <span class="messageList-item-system"><strong><user-name :user="v" :reportable="true" /></strong>さんが退室しました</span>
          </template>
        </li>
        <li class="messageList-empty" v-if="!messages.length">メッセージがまだありません</li>
      </ul>
    </div>
    <form @submit.prevent="sendMessage" class="chatForm">
      <input type="text" placeholder="雑談用チャット" v-model="model" class="chatForm-input" :maxlength="verified ? 255 : 100">
      <loading v-if="isLoading" class="loading" />
      <button v-else class="chatForm-button" :class="{ disabled: !canSendMessage }"><i class="material-icons">send</i>送信</button>
    </form>
  </div>
</template>

<script>
import { computed, inject, onMounted, onUpdated, ref, watch } from 'vue'
import Loading from '@/components/Loading'
import UserName from '@/components/UserName'
export default {
  components: { Loading, UserName },
  props: ['messages'],
  emits: ['updated'],
  setup (props, context) {
    const axios = inject('axios')
    const data = inject('data')
    const verified = computed(() => data.value.entered?.semi_verified || data.value.entered?.verified)
    const model = ref('')
    const isLoading = ref(false)
    const playSound = inject('playSound')
    const canSendMessage = computed(() => {
      return model.value.trim() && data.value.entered
    })
    const sendMessage = () => {
      if (!canSendMessage.value) return
      if (!verified.value && props.messages.slice(-2).every(v => v.user_id === data.value.entered.user_id)) {
        alert('荒らし防止の為、ゲストユーザーは利用開始から3日経過するまで連続投稿が制限されています')
        return
      }
      if (!verified.value && model.value.includes('://')) {
        alert('荒らし防止の為、ゲストユーザーはURLの投稿が制限されています')
        return
      }
      isLoading.value = true
      const params = new URLSearchParams()
      params.append('text', model.value.trim())
      model.value = ''
      return axios.post('/api/chat.php', params).then(res => {
        context.emit('updated', res.data)
      }).catch(() => {
        alert('送信に失敗しました')
      }).finally(() => {
        isLoading.value = false
      })
    }
    const list = ref(null)
    let scrollUpdateFlg = false
    const isBottom = elm => {
      const scrollBottom = elm.scrollTop + elm.clientHeight
      return (elm.scrollHeight - scrollBottom) < 30
    }
    const updateScroll = () => {
      list.value.scrollTop = list.value.scrollHeight - list.value.clientHeight
    }
    watch(() => props.messages[props.messages.length - 1]?.chat_id, (newLength, oldLength) => {
      scrollUpdateFlg = list.value && isBottom(list.value)
      if (newLength > oldLength) playSound('chat')
    })
    onMounted(updateScroll)
    onUpdated(() => {
      if (!scrollUpdateFlg) return
      scrollUpdateFlg = false
      updateScroll()
    })
    return {
      data,
      verified,
      model,
      list,
      canSendMessage,
      sendMessage,
      isLoading
    }
  }
}
</script>

<style lang="scss" scoped>
.messageComponent {
  display: flex;
  flex-direction: column;
  &-list {
    flex: 1;
    position: relative;
    padding: 6px;
    background: var(--bg-color);
    border-radius: var(--radius);
    overflow: hidden;
  }
}
.messageList {
  padding: 8px;
  max-height: 468px;
  font-size: 13px;
  overflow-y: auto;
  &-item {
    padding: 7px 0;
    border-bottom: 1px dashed var(--border-color);
    word-break: break-word;
    &.readMore {
      text-align: center;
    }
    &-name {
      display: inline-flex;
      align-items: center;
      margin-right: 0.5em;
      vertical-align: middle;
      &.loggedInUser {
        font-weight: bold;
        color: var(--green);
      }
    }
    &-text {
      vertical-align: middle;
    }
    &-system {
      vertical-align: middle;
      color: var(--green);
    }
  }
  &-empty {
    font-size: 13px;
  }
}
button {
  i {
    transform: rotate(-45deg) translate(0, -2px);
  }
}
@media screen and (max-width: 1200px) {
  .messageList {
    height: 270px;
  }
}
.chatForm {
  margin-top: 10px;
  &-input {
    margin-bottom: 10px;
  }
}
.loading {
  margin: 17px auto 4px;
}
</style>
