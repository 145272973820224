<template>
  <div class="painter" :class="{ reverseLayout }">
    <div class="canvasContainer">
      <div
        class="canvases"
        :class="{ transparent: transparentBg, spuit: brush.sub === BRUSHS.SPUIT, zoom: brush.sub === BRUSHS.ZOOM, grab: zoom.grab, grabbing: zoom.grabbing }"
        :style="{ transform: `scale(${zoom.value})`, transformOrigin: `${zoom.originX * 100}% ${zoom.originY * 100}%` }"
      >
        <canvas v-for="layer in layers" :key="layer.id" :ref="ref => layer.ref = ref"
          :class="{ hidden: !layer.show }"
          :name="layer.name"
          :width="WIDTH"
          :height="HEIGHT"
          @mousedown="onStart"
          @mousemove="onMove"
          @mouseup="onMouseup"
          @mouseenter="onMouseenter"
          @mouseleave="onMouseleave"
          @touchstart="onStart"
          @touchmove="onMove"
          @touchcancel="onMouseup"
          @touchend="onMouseup"
        ></canvas>
      </div>
    </div>
    <div class="controllers">
      <div class="systemController">
        <div class="iconBtn" :class="{ disabled: !checkUndo(-1) }" @click="undo(-1)" title="Ctrl + Z"><img src="/img/undo.svg"></div>
        <div class="iconBtn" :class="{ disabled: !checkUndo(1) }" @click="undo(1)" title="Ctrl + Y / Ctrl + Shif + Z"><img src="/img/redo.svg"></div>
        <div class="iconBtn" :class="{ active: transparentBg }" @click="transparentBg = !transparentBg"><img src="/img/bg.svg"></div>
        <div class="iconBtn" @click="resetConfirm"><img src="/img/trash.svg"></div>
      </div>
      <div class="brushController">
        <div class="label">ブラシ</div>
        <span class="brushSizeLabel">{{ sizeToLineWidth(brush.size) }}</span>
        <input type="range" min="1" max="16" v-model="brush.size" title="[ ]">
        <ul class="brushList">
          <li>
            <input v-show="false" id="pen" type="radio" v-model="brush.type" :value="BRUSHS.PEN">
            <label class="iconBtn" for="pen" title="P"><img src="/img/pen.svg"></label>
          </li>
          <li>
            <input v-show="false" id="marker" type="radio" v-model="brush.type" :value="BRUSHS.MARKER">
            <label class="iconBtn" for="marker" title="P"><img src="/img/marker.svg"></label>
          </li>
          <li>
            <input v-show="false" id="brush" type="radio" v-model="brush.type" :value="BRUSHS.BRUSH">
            <label class="iconBtn" for="brush" title="B"><img src="/img/brush.svg"></label>
          </li>
          <li>
            <div class="iconBtn" :class="{ active: brush.sub === BRUSHS.ERASER }" title="E" @click="setSub(BRUSHS.ERASER)"><img src="/img/eraser.svg"></div>
          </li>
          <li>
            <div class="iconBtn" :class="{ active: brush.sub === BRUSHS.SPUIT }" title="I" @click="setSub(BRUSHS.SPUIT)"><img src="/img/spuit.svg"></div>
          </li>
          <li>
            <div class="iconBtn" for="zoom" title="/" v-if="zoom.value > 1" @click="zoom.value = 1"><img src="/img/zoom_out.svg"></div>
            <div class="iconBtn" :class="{ active: brush.sub === BRUSHS.ZOOM }" title="/" v-else @click="setSub(BRUSHS.ZOOM)"><img src="/img/zoom_in.svg"></div>
          </li>
        </ul>
      </div>
      <div class="colorController">
        <div class="label">カラー</div>
        <div class="colorController-color">
          <label class="colorController-color-picker colorPicker">
            <input class="colorPicker-input" type="color" v-model="brush.color">
            <div class="colorPicker-preview" :style="{ background: brushColor }" />
          </label>
          <div class="colorController-color-palette" @click="paletteOpened = !paletteOpened">
            <img src="/img/palette.svg">
          </div>
        </div>
        <input class="colorAlpha" type="range" min="0.0" max="1.0" step="0.01" v-model="brush.alpha">
        <div class="colorPalette" v-show="paletteOpened">
          <ul class="colorPalette-preset">
            <li class="colorPalette-preset-item" v-for="(v, i) in COLOR_PRESET" :key="i" :style="{ backgroundColor: v }" @click="selectPaletteColor(v)" />
          </ul>
          <ul class="colorPalette-list">
            <li class="colorPalette-list-item" v-for="(v, i) in colorHistory" :key="i" :style="{ backgroundColor: v }" @click="selectPaletteColor(v)" />
          </ul>
        </div>
      </div>
      <div class="layerController">
        <div class="label">レイヤー</div>
        <ul class="layerList">
          <li v-for="(layer, i) in layers" :key="layer.id" :class="{ active: layer === activeLayer }">
            <div class="layerItem" @click="layerData.index = i">{{ layer.name }}</div>
            <div class="iconBtn" @click="toggleShowLayer(layer, false)" v-if="layer.show"><img src="/img/eye_open.svg"></div>
            <div class="iconBtn" @click="toggleShowLayer(layer, true)" v-if="!layer.show"><img src="/img/eye_close.svg"></div>
          </li>
        </ul>
        <ul class="layerController-btns">
          <li class="iconBtn" :class="{ disabled: layers.length >= LAYER_MAX }" @click="addLayer"><img src="/img/plus.svg"></li>
          <li class="iconBtn" :class="{ disabled: !layers[layerData.index + 1] }" @click="moveLayer(1)"><img src="/img/up.svg"></li>
          <li class="iconBtn" :class="{ disabled: !layers[layerData.index - 1] }" @click="moveLayer(-1)"><img src="/img/down.svg"></li>
          <li class="iconBtn" @click="editLayerName"><img src="/img/edit.svg"></li>
          <li class="iconBtn" :class="{ disabled: layers.length <= 1 }" @click="removeLayer"><img src="/img/trash.svg"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onBeforeUnmount, onMounted, onUpdated, reactive, ref } from 'vue'
const BRUSHS = {
  PEN: 1,
  MARKER: 2,
  BRUSH: 3,
  ERASER: 4,
  SPUIT: 5,
  ZOOM: 6
}
const WIDTH = 640
const HEIGHT = 480
const THUMB_WIDTH = 200
const THUMB_HEIGHT = 150
const UNDO_MAX = 10
const LAYER_MAX = 6
const COLOR_PRESET = [
  '#999999', '#cccccc', '#ffffff',
  '#111111', '#333333', '#666666',
  '#332211', '#775533', '#ccaa77',
  '#aa1111', '#cc3333', '#ee7777',
  '#cc2255', '#ee5588', '#ff88bb',
  '#8811aa', '#bb33dd', '#cc66ee',
  '#113399', '#2255cc', '#7799ee',
  '#1199aa', '#55ccee', '#77eeff',
  '#117722', '#339944', '#66cc77',
  '#668811', '#88aa22', '#aacc55',
  '#998811', '#ddcc11', '#ffee77',
  '#ee6622', '#ff9933', '#ffcc77',
  '#fdd099', '#ffE0B0', '#fff5e0'
]
export default {
  emits: ['updated'],
  setup (_, context) {
    const layerData = reactive({
      list: [],
      count: 0,
      index: 0,
      revision: 0
    })
    const zoom = reactive({
      originX: 0.5,
      originY: 0.5,
      value: 1,
      grab: false,
      grabbing: false
    })
    const pointer = { clientX: 0, clientY: 0 }
    onMounted(() => {
      layerData.list.forEach(layer => {
        layer.ctx = layer.ref.getContext('2d')
      })
    })
    onUpdated(() => {
      layerData.list.forEach(layer => {
        layer.ctx = layer.ref.getContext('2d')
      })
    })
    const drawn = inject('drawn')
    const reverseLayout = inject('reverseLayout')
    const transparentBg = ref(true)
    const activeLayer = computed(() => layerData.list[layerData.index])
    const brush = reactive({
      drawing: false,
      currentX: null,
      currentY: null,
      type: BRUSHS.PEN,
      sub: null,
      size: 3,
      color: '#000000',
      alpha: 1
    })
    const setSub = val => {
      brush.sub = brush.sub === val ? null : val
    }
    const brushColor = computed(() => {
      const r = parseInt(brush.color.slice(1, 3), 16)
      const g = parseInt(brush.color.slice(3, 5), 16)
      const b = parseInt(brush.color.slice(5, 7), 16)
      return `rgba(${r}, ${g}, ${b}, ${brush.alpha})`
    })
    const colorHistory = reactive([])
    const saveColorHistory = color => {
      if (COLOR_PRESET.includes(color) || colorHistory.includes(color)) return
      colorHistory.unshift(color)
      colorHistory.splice(13)
    }
    const eventToPos = e => {
      const x = e.clientX !== undefined ? e.clientX : e.touches[0].clientX
      const y = e.clientY !== undefined ? e.clientY : e.touches[0].clientY
      return { x, y }
    }
    const eventToRelativePos = e => {
      const rect = e.target.getBoundingClientRect()
      const ratio = rect.width / WIDTH
      const { x, y } = eventToPos(e)
      return { x: (x - rect.left) / ratio, y: (y - rect.top) / ratio }
    }
    const draw = e => {
      e.preventDefault()
      const { x: newX, y: newY } = eventToRelativePos(e)
      if (zoom.grabbing) {
        const { x, y } = eventToPos(e)
        const moveX = (x - pointer.clientX) / WIDTH
        const moveY = (y - pointer.clientY) / HEIGHT
        zoom.originX = Math.min(Math.max(zoom.originX - moveX, 0), 1)
        zoom.originY = Math.min(Math.max(zoom.originY - moveY, 0), 1)
      } else if (brush.sub === BRUSHS.ZOOM) {
        zoom.value = 2.5
        zoom.originX = newX / WIDTH
        zoom.originY = newY / HEIGHT
      } else if (brush.sub === BRUSHS.SPUIT) {
        const spuitImage = activeLayer.value.ctx.getImageData(newX, newY, 1, 1)
        brush.color = `#${Array.from(spuitImage.data).slice(0, 3).map(v => v.toString(16).padStart(2, '0')).join('')}`
        brush.alpha = spuitImage.data[3] / 255
      } else {
        activeLayer.value.ctx.beginPath()
        activeLayer.value.ctx.moveTo(brush.currentX, brush.currentY)
        activeLayer.value.ctx.lineTo(newX, newY)
        activeLayer.value.ctx.stroke()
        activeLayer.value.ctx.closePath()
      }
      brush.currentX = newX
      brush.currentY = newY
    }
    const history = reactive({
      list: [],
      point: { i: 0, k: 'before' }
    })
    const addLayer = () => {
      if (layerData.list.length >= LAYER_MAX) return
      layerData.index = layerData.list.length
      layerData.count++
      const id = Math.random().toString(32).substring(2)
      const layer = { id, name: `Layer${layerData.count}`, show: true, ref: ref(null), ctx: null }
      layerData.list.push(layer)
      return layer
    }
    const removeLayer = () => {
      if (layerData.list.length > 1 && activeLayer.value && confirm('レイヤー「' + activeLayer.value.name + '」を削除しますか？')) {
        layerData.list.splice(layerData.index, 1)
        if (layerData.index > 0) layerData.index--
        context.emit('updated')
      }
    }
    const editLayerName = () => {
      const newName = prompt('レイヤー名の変更', activeLayer.value.name)
      if (!newName) return
      activeLayer.value.name = newName.substr(0, 9)
    }
    const init = () => {
      drawn.value = false
      layerData.list.splice(0)
      layerData.revision = 0
      history.list.splice(0)
      history.point.i = 0
      history.point.k = 'before'
      colorHistory.splice(0)
    }
    const resetCanvas = () => {
      init()
      addLayer()
    }
    const saveBackup = () => {
      const layers = layerData.list.map(layer => {
        return {
          name: layer.name,
          show: layer.show,
          data: layer.ctx.canvas.toDataURL('image/png')
        }
      })
      const data = { count: layerData.count, list: layers }
      try {
        localStorage.setItem('backup', JSON.stringify(data))
      } catch {
        console.warn('バックアップを保存できません')
      }
    }
    const getBackupData = () => {
      const backupJson = localStorage.getItem('backup')
      try {
        return JSON.parse(backupJson)
      } catch {
        localStorage.removeItem('backup')
        console.warn('バックアップを復元できません')
      }
    }
    const loadBackup = () => {
      const backup = getBackupData()
      if (!backup) return
      init()
      drawn.value = true
      backup.list.forEach(v => {
        const layer = addLayer(v.name)
        layer.show = v.show
        layer.name = v.name
        const img = new Image()
        img.src = v.data
        img.onload = () => layer.ctx.drawImage(img, 0, 0, 640, 480)
      })
      layerData.count = backup.count
    }
    const toggleShowLayer = (layer, bool) => {
      layer.show = bool
      context.emit('updated')
    }
    const moveLayer = (diff) => {
      const current = layerData.index
      const to = layerData.index + diff
      if (layerData.list[to]) {
        if (diff < 0) {
          layerData.list.splice(to, 2, layerData.list[current], layerData.list[to])
        } else {
          layerData.list.splice(current, 2, layerData.list[to], layerData.list[current])
        }
        const currentCtx = layerData.list[current].ctx
        const toCtx = layerData.list[to].ctx
        layerData.list[current].ctx = toCtx
        layerData.list[to].ctx = currentCtx
        layerData.index = to
        context.emit('updated')
      }
    }
    const saveNewLog = () => {
      const splicePoint = history.point.k === 'before' ? history.point.i : history.point.i + 1
      if (history.list.length > splicePoint) {
        history.list.splice(splicePoint, history.list.length - splicePoint)
      }
      if (history.list.length >= UNDO_MAX) {
        history.list.splice(0, 1)
      }
      history.list.push({
        ctx: activeLayer.value.ctx,
        before: activeLayer.value.ctx.getImageData(0, 0, 640, 480),
        after: null
      })
      history.point = { i: history.list.length - 1, k: 'before' }
    }
    const saveAfterLog = () => {
      history.list[history.point.i].after = history.list[history.point.i].ctx.getImageData(0, 0, 640, 480)
      history.point.k = 'after'
    }
    const getUndoPoint = (diff) => {
      const key = diff < 0 ? 'before' : 'after'
      const newIndex = history.point.k === key ? history.point.i + diff : history.point.i
      return { i: newIndex, k: key }
    }
    const checkUndo = (diff) => {
      const point = getUndoPoint(diff)
      return history.list[point.i] && history.list[point.i][point.k]
    }
    const undo = (diff) => {
      const point = getUndoPoint(diff)
      if (history.list[point.i]) {
        history.list[point.i].ctx.putImageData(history.list[point.i][point.k], 0, 0)
        history.point.i = point.i
        history.point.k = point.k
        context.emit('updated')
      }
    }
    const sizeToLineWidth = size => {
      const ratio = size > 9 ? size - 9 : 1
      return size * ratio
    }
    const reloadBrush = ctx => {
      ctx.lineWidth = sizeToLineWidth(brush.size)
      ctx.strokeStyle = brushColor.value
      switch (brush.type) {
        case BRUSHS.PEN:
          ctx.shadowBlur = 0
          ctx.shadowColor = 'rgba(0,0,0,0.0)'
          ctx.globalAlpha = 1
          ctx.lineJoin = 'round'
          ctx.lineCap = 'round'
          break
        case BRUSHS.MARKER:
          ctx.shadowBlur = ctx.lineWidth
          ctx.shadowColor = ctx.strokeStyle
          ctx.globalAlpha = 1
          ctx.lineJoin = 'round'
          ctx.lineCap = 'round'
          break
        case BRUSHS.BRUSH:
          ctx.shadowBlur = ctx.lineWidth
          ctx.shadowColor = ctx.strokeStyle
          ctx.globalAlpha = 0.1
          ctx.lineJoin = 'miter'
          ctx.lineCap = 'butt'
          break
      }
      ctx.globalCompositeOperation = brush.sub === BRUSHS.ERASER ? 'destination-out' : 'source-over'
    }
    const onStart = e => {
      document.activeElement.blur()
      reloadBrush(activeLayer.value.ctx)
      saveColorHistory(brush.color)
      drawn.value = true
      brush.drawing = true
      zoom.grabbing = zoom.grab
      if (![BRUSHS.SPUIT, BRUSHS.ZOOM].includes(brush.sub) && !zoom.grabbing) saveNewLog()
      const { x, y } = eventToRelativePos(e)
      brush.currentX = x
      brush.currentY = y
      draw(e)
    }
    const onMouseup = e => {
      if (!brush.drawing) return
      if (![BRUSHS.SPUIT, BRUSHS.ZOOM].includes(brush.sub) && !zoom.grabbing) {
        saveAfterLog()
        context.emit('updated')
      }
      brush.drawing = false
      zoom.grabbing = false
      if ([BRUSHS.SPUIT, BRUSHS.ZOOM].includes(brush.sub)) brush.sub = null
      layerData.revision++
      if ((layerData.revision % 5) === 0) saveBackup()
    }
    const onMove = e => {
      if (brush.drawing) draw(e)
      const { x, y } = eventToPos(e)
      pointer.clientX = x
      pointer.clientY = y
    }
    const onMouseenter = e => {
      const { x, y } = eventToRelativePos(e)
      brush.currentX = x
      brush.currentY = y
    }
    const onMouseleave = e => {
      brush.currentX = null
      brush.currentY = null
    }
    const onKeyDown = e => {
      if (document.activeElement !== document.body) return
      zoom.grab = e.shiftKey
      if (e.ctrlKey && (e.key === 'y' || e.key === 'Z')) {
        e.preventDefault()
        if (checkUndo(1)) undo(1)
      } else if (e.ctrlKey && e.key === 'z') {
        e.preventDefault()
        if (checkUndo(-1)) undo(-1)
      } else if (e.key === 'i') {
        brush.sub = brush.sub === BRUSHS.SPUIT ? null : BRUSHS.SPUIT
      } else if (e.key === 'p') {
        if (brush.sub === BRUSHS.SPUIT) {
          brush.type = brush.type === BRUSHS.BRUSH ? BRUSHS.PEN : brush.type
          brush.sub = null
        } else {
          brush.type = brush.type === BRUSHS.PEN ? BRUSHS.MARKER : BRUSHS.PEN
        }
      } else if (e.key === 'b') {
        brush.type = BRUSHS.BRUSH
        brush.sub = null
      } else if (e.key === 'e') {
        brush.sub = brush.sub === BRUSHS.ERASER ? null : BRUSHS.ERASER
      } else if (e.key === '/') {
        if (zoom.value > 1) {
          zoom.value = 1
        } else {
          brush.sub = brush.sub === BRUSHS.ZOOM ? null : BRUSHS.ZOOM
        }
      } else if (e.key === ']') {
        brush.size = Math.min(brush.size + 1, 16)
      } else if (e.key === '[') {
        brush.size = Math.max(brush.size - 1, 1)
      }
    }
    const onKeyUp = e => {
      if (zoom.grab) zoom.grab = e.shiftKey
      if (zoom.grabbing) zoom.grabbing = e.shiftKey
    }
    const onWheel = e => {
      if (!e.ctrlKey) return
      e.preventDefault()
      const { x, y } = eventToRelativePos(e)
      if (e.deltaY < 0) {
        zoom.originX = x / WIDTH
        zoom.originY = y / HEIGHT
      }
      zoom.value = Math.min(Math.max(zoom.value - (e.deltaY * 0.005), 1), 3)
    }
    window.addEventListener('mouseup', onMouseup)
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)
    window.addEventListener('wheel', onWheel, { passive: false })
    onBeforeUnmount(() => {
      window.removeEventListener('mouseup', onMouseup)
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
      window.removeEventListener('wheel', onWheel)
      drawn.value = false
    })
    const getLayerImages = () => {
      const promises = layerData.list.filter(layer => layer.show).map(layer => {
        return new Promise(resolve => {
          const image = new Image()
          image.src = layer.ctx.canvas.toDataURL()
          image.onload = () => resolve(image)
        })
      })
      return Promise.all(promises)
    }
    const getImageData = () => {
      return getLayerImages().then(images => {
        const canvas = document.createElement('canvas')
        canvas.width = WIDTH
        canvas.height = HEIGHT
        const ctx = canvas.getContext('2d')
        images.forEach(image => ctx.drawImage(image, 0, 0))
        return canvas.toDataURL('image/png')
      })
    }
    const getThumbData = () => {
      return getLayerImages().then(images => {
        const canvas = document.createElement('canvas')
        canvas.width = THUMB_WIDTH
        canvas.height = THUMB_HEIGHT
        const ctx = canvas.getContext('2d')
        ctx.fillStyle = '#EEEAE5'
        ctx.fillRect(0, 0, THUMB_WIDTH, THUMB_HEIGHT)
        images.forEach(image => ctx.drawImage(image, 0, 0, WIDTH, HEIGHT, 0, 0, THUMB_WIDTH, THUMB_HEIGHT))
        return canvas.toDataURL('image/jpeg', 0.3)
      })
    }
    resetCanvas()
    loadBackup()
    const resetConfirm = () => {
      if (!confirm('全てのレイヤーのイラストをリセットしますか？')) return
      resetCanvas()
      context.emit('updated')
      localStorage.removeItem('backup')
    }
    const paletteOpened = ref(false)
    const selectPaletteColor = v => {
      brush.color = v
      paletteOpened.value = false
    }
    return {
      BRUSHS,
      COLOR_PRESET,
      reverseLayout,
      getImageData, getThumbData,
      WIDTH, HEIGHT, UNDO_MAX, LAYER_MAX,
      layerData, brush, setSub, transparentBg,
      zoom,
      activeLayer,
      addLayer, moveLayer, removeLayer, editLayerName,
      toggleShowLayer, resetCanvas, resetConfirm,
      checkUndo, undo,
      sizeToLineWidth, brushColor,
      colorHistory, paletteOpened, selectPaletteColor,
      onStart, onMove, onMouseup, onMouseenter, onMouseleave,
      layers: computed(() => layerData.list),
      log: computed(() => history.list),
      logPoint: computed(() => history.point)
    }
  }
}
</script>

<style lang="scss" scoped>
.iconBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 6px;
  user-select: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
}
.iconBtn.disabled {
  opacity: 0.3;
  cursor: default;
}
input:checked + .iconBtn,
.iconBtn.active {
  background: rgba(0, 0, 0, 0.1);
}
.iconBtn img {
  width: 90%;
}
.label {
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
}
.painter {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  &.reverseLayout {
    flex-direction: row-reverse;
  }
}
.canvasContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}
.canvases {
  position: relative;
  background: #FAFAFA;
  overflow: hidden;
  margin: auto;
  font-size: 0;
  cursor: crosshair;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  &.transparent {
    background-image: linear-gradient(45deg, #AAA 25%, transparent 25%, transparent 75%, #AAA 75%, #AAA),
                      linear-gradient(45deg, #AAA 25%, transparent 25%, transparent 75%, #AAA 75%, #AAA);
    background-size: 24px 24px;
    background-position: 0 0, 12px 12px;
    box-shadow: none;
  }
  &.spuit {
    cursor: pointer;
  }
  &.zoom {
    cursor: zoom-in;
  }
  &.grab {
    cursor: grab;
  }
  &.grabbing {
    cursor: grabbing;
  }
}
.canvases canvas {
  &:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}
.controllers {
  display: flex;
  flex-direction: column;
  width: 190px;
  padding: 11px;
  background: var(--bg-color);
  border-radius: var(--radius);
}
.painter:not(.reverseLayout) .controllers {
  margin-left: 10px;
}
.painter.reverseLayout .controllers {
  margin-right: 10px;
}
.systemController,
.brushController,
.colorController {
  position: relative;
  margin-bottom: 15px;
}
.systemController {
  display: flex;
  .iconBtn:last-child {
    margin-left: auto;
  }
}
.brushController {
  display: flex;
  flex-direction: column;
}
.brushSizeLabel {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
}
.brushList {
  display: flex;
  margin-top: 10px;
  li:nth-of-type(3) {
    margin-right: 1px;
  }
}
.color input {
  display: block;
  width: 100%;
}
.colorController {
  &-color {
    display: flex;
    align-items: center;
    margin-top: 10px;
    &-picker {
      flex: 1;
    }
    &-palette {
      margin-left: 8px;
      width: 18px;
      cursor: pointer;
      img {
        display: block;
      }
    }
  }
}
.colorPalette {
  position: absolute;
  top: 15px;
  right: -9px;
  transform: translateY(-100%);
  padding: 7px;
  border-radius: 7px 7px 0 7px;
  max-width: none;
  background: var(--bg-color);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
  &-preset {
    display: grid;
    grid-template-rows: auto auto auto;
    &-item {
      &:nth-of-type(3n + 1) {
        grid-row: 1 / 2;
      }
      &:nth-of-type(3n + 2) {
        grid-row: 2 / 3;
      }
      &:nth-of-type(3n + 0) {
        grid-row: 3 / 4;
      }
      width: 13px;
      height: 13px;
    }
  }
  &-list {
    display: flex;
    &-item {
      margin-top: 7px;
      width: 13px;
      height: 13px;
    }
  }
}
.colorPicker {
  position: relative;
  display: block;
  border: 1px solid var(--border-color);
  background: #EEE;
  background-image: linear-gradient(45deg, #AAA 25%, transparent 25%, transparent 75%, #AAA 75%, #AAA),
                    linear-gradient(45deg, #AAA 25%, transparent 25%, transparent 75%, #AAA 75%, #AAA);
  background-size: 12px 12px;
  background-position: 0 0, 6px 6px;
  &-preview {
    height: 30px;
  }
  &-input {
    position: absolute;
    appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.colorAlpha {
  margin-top: 7px;
  width: 100%;
}
.layerController {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.layerController-btns {
  display: flex;
  justify-content: flex-end;
}
.layerController-btns li {
  margin-left: 2px;
  &:last-child {
    margin-left: auto;
  }
}
.layerList {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 10px;
  border: 1px solid var(--border-color);
  height: 134px;
  overflow-y: auto;
  li {
    cursor: pointer;
    margin: 0;
    font-size: 13px;
    line-height: 1.3;
    text-align: center;
    user-select: none;
    position: relative;
    &:first-child {
      margin-bottom: auto;
    }
    .layerItem {
      border-bottom: 1px solid var(--border-color);
      padding: 8px 8px 8px 12px;
    }
    &.active .layerItem {
      background: rgba(0, 0, 0, 0.1);
      border-color: rgba(0, 0, 0, 0.1);
      font-weight: bold;
      color: var(--green);
    }
    &:first-child .layerItem,
    &.active:first-child .layerItem {
      border-bottom-color: transparent;
    }
    &:last-child .layerItem,
    &.active:last-child .layerItem {
      border-top-color: transparent;
    }
    .iconBtn {
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }
}
@media screen and (max-width: 900px) {
  .painter {
    display: block;
    flex-direction: column;
  }
  .painter.reverseLayout .controllers,
  .painter:not(.reverseLayout) .controllers {
    margin: 10px 0 0;
  }
  .controllers {
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 70px auto auto;
    grid-template-areas:
      'system brush layer'
      'system color layer';
    .systemController {
      margin-right: 12px;
      grid-area: system;
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: 36px auto;
      .iconBtn {
        margin: 0 auto;
        &:last-child {
          grid-column: 1 / 3;
        }
      }
    }
    .brushController {
      grid-area: brush;
    }
    .colorController {
      margin: 0;
      grid-area: color;
    }
    .colorPalette {
      right: -4px;
      transform: translateY(-100%);
      &-preset-item,
      &-list-item {
        width: 20px;
        height: 20px;
      }
    }
    .layerController {
      margin-left: 12px;
      grid-area: layer;
    }
  }
}
@media screen and (max-width: 500px) {
  .controllers {
    grid-template-columns: 70px auto;
    grid-template-areas:
      'system brush'
      'system color'
      'layer layer';
    .layerController {
      margin-left: 0;
    }
    .layerList {
      height: auto;
    }
  }
}
</style>
